import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import Resume from './Resume';
import { getFechaActual } from '../../util/util';

export default function Reserve() {
    const navigate = useNavigate();
    const [pasoActual, setPasoActual] = useState(1);
    const [datosPersonales, setDatosPersonales] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        numero: '',
        genero: '',
        otro: []
    });
    const [servicios, setServicios] = useState([]);
    const [info, setInfo] = useState({
        local: '',
        local_id: '',
        employeeID: '',
        fecha: getFechaActual(),
        hora: '',
        duration: 0,
        horaFinal: '',
    });
    const [generoAnterior, setGeneroAnterior] = useState('');
    const [isWorkoutTeensChecked, setIsWorkoutTeensChecked] = useState(false);
    const timeWorkoutTeens = 15;


    const handleSiguiente = () => {
        if (generoAnterior && datosPersonales.genero !== generoAnterior) {
            setServicios([]);
            setInfo({
                ...info,
                duration: 0,
            });
        }
        setGeneroAnterior(datosPersonales.genero);
        setPasoActual(pasoActual + 1);
    };

    const handleAnterior = () => {
        setPasoActual(pasoActual - 1);
    };

    const onCLose = () => {
        setPasoActual(1);
        setDatosPersonales({
            nombre: '',
            correo: '',
            numero: '',
            genero: ''
        });
        setServicios([]);

        setInfo({
            local: '',
            local_id: '',
            employeeID: '',
            fecha: getFechaActual(),
            hora: '',
            duration: 0,
            horaFinal: '',
        });
        setPasoActual(1)
        navigate('/');
    };


    return (
        <div className="container-fluid mt-5">
            {pasoActual === 1 && (
                <FirstStep {...{ datosPersonales, setDatosPersonales, onNext: handleSiguiente }} />
            )}
            {pasoActual === 2 && (
                <SecondStep {...{ info, setInfo, servicios, setServicios, onNext: handleSiguiente, onPrev: handleAnterior, gender: datosPersonales.genero, isWorkoutTeensChecked, setIsWorkoutTeensChecked, timeWorkoutTeens}} />
            )}
            {pasoActual === 3 && (
                <ThirdStep {...{ onNext: handleSiguiente, onPrev: handleAnterior, info, setInfo, fechaPaso: info.fecha }} />
            )}
            {pasoActual === 4 && (
                <Resume {...{ onPrev: handleAnterior, info, servicios, datosPersonales, onCLose, isWorkoutTeensChecked, timeWorkoutTeens }} />
            )}
            

        </div>
    );
}