import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar';
import api from '../../../api/api';
import "./Styles.css"
import { AuthContext } from '../../../Context/AuthContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { createLocalApi, getEmpleados, getLocalApi, updateEmpleado, updateLocalApi } from '../../../services/apiService';
import { mostrarMensajeAlerta } from '../../../util/alet';

export default function Local() {
    const navigator = useNavigate()
    const [empleados, setEmpleados] = useState([]);
    const [localesDisponibles, setLocalesDisponibles] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false); // Estado para controlar si el modal de edición está abierto
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false); // Estado para controlar si el modal de creación está abierto
    const [localID, setLocalID] = useState("");
    const [localesConEmpleados, setLocalesConEmpleados] = useState([]);
    const [localesSinEmpleados, setLocalesSinEmpleados] = useState([]);
    const [local, setLocal] = useState({})
    const { user } = useContext(AuthContext);

    const handleEliminateLocal = async (id) => {
        if (user.permisos?.locales.eliminar) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: 'Esta acción eliminará el local permanentemente.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await api.delete(`/local/${id}`);
                        await fetchLocales();
                        setLocalID("")
                        // Si la eliminación se realiza con éxito, puedes mostrar un mensaje de éxito
                        mostrarMensajeAlerta('success', 'Éxito', '¡El local se ha eliminado correctamente!', false);
                    } catch (error) {
                        mostrarMensajeAlerta("error", "Error", '¡Ha ocurrido un error al eliminar el local!', true);
                    }
                }
            });
        } else {
            mostrarMensajeAlerta("error", "Error",
                '¡No tienes permisos!', false);
        }
    }

    const fetchLocales = async () => {
        try {
            const response = await api.get('/local');
            if (response.status === 200) {
                setLocalesDisponibles(response.data.locals);
            }
        } catch (error) {
            console.error('Error al obtener la lista de locales:', error);
        }
    }
    useEffect(() => {
        async function fetchLocales1() {
            await fetchLocales();
        }
        fetchLocales1();
    }, []);

    const localSelected = async (id) => {
        try {
            setLocalID(id);
            const localNuevo = localesDisponibles.find((local) => local._id === id);
            if (localNuevo) {
                setLocal(localNuevo)
            }
            const response = await getLocalApi(id)
            setLocalesConEmpleados(response.localesConEmpleados);
            setLocalesSinEmpleados(response.sinEmpleados);
        } catch (error) {
            console.error('Error al obtener la lista de locales:', error);
        }
    }
    const handleAgregarEmpleado = async () => {
        const inputOptions = {};
        localesSinEmpleados.forEach((empleado) => {
            inputOptions[empleado._id] = empleado.indentification;
        });

        const result = await Swal.fire({
            title: 'Selecciona un empleado',
            input: 'select',
            inputOptions: inputOptions,
            inputPlaceholder: 'Selecciona un empleado',
            showCancelButton: true,
            confirmButtonText: 'Agregar',
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            const empleadoSeleccionadoId = result.value;
            const empleadoSeleccionado = localesSinEmpleados.find((empleado) => empleado._id === empleadoSeleccionadoId);
            if (empleadoSeleccionado) {
                // Agrega el empleado seleccionado a la lista de empleados del local
                local.employees.push(empleadoSeleccionado);
                try {
                    await updateLocalApi(localID, local?.indentification, local.employees);
                    await localSelected(localID);
                } catch (error) {
                    console.error('Error al agregar empleado:', error);
                }
            }
        }
    };


    // Quitar Empleado
    const handleQuitarEmpleado = async (empleadoId) => {
        const empleadoSeleccionado = local.employees.find((empleado) => empleado._id === empleadoId);
        if (empleadoSeleccionado) {
            // Muestra un modal de confirmación antes de quitar al empleado
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Esta acción quitará al empleado del local.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, quitar',
                cancelButtonText: 'Cancelar',
            })
            if (result.isConfirmed) {
                const updatedEmployees = local.employees.filter((empleado) => empleado._id !== empleadoId);
                try {
                    await updateLocalApi(localID, local?.indentification, updatedEmployees);
                    await localSelected(localID);
                } catch (error) {
                    console.error('Error al quitar empleado:', error);
                }
            }

        }
    };


    const handleCrearLocal = async () => {
        const result = await Swal.fire({
            title: 'Crear Nuevo Local',
            input: 'text',
            inputPlaceholder: 'Nombre del local',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            const nombreLocal = result.value;
            try {
                await createLocalApi(nombreLocal);
                await fetchLocales();
            } catch (error) {
                console.error('Error al crear empleado:', error);
            }
        }
    };


    const handleEditarLocal = async () => {
        const result = await Swal.fire({
            title: 'Editar Local',
            input: 'text',
            inputValue: local?.local, // Valor actual del local seleccionado
            inputPlaceholder: 'Nombre del local',
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
        })
        if (result.isConfirmed) {
            const nombreLocal = result.value;
            try {
                await updateLocalApi(localID, nombreLocal, localesConEmpleados);
                await fetchLocales();
            } catch (error) {
                console.error('Error al editar empleado:', error);
            }
        }
    };

    const handleChangeLocal = async (empleado) => {
        const inputOptions = {};
        localesDisponibles.forEach((empleado) => {
            if (empleado._id !== localID) {
                inputOptions[empleado._id] = empleado.local;
            }
        });
        // Muestra un modal para seleccionar un nuevo local
        const result = await Swal.fire({
            title: 'Selecciona un nuevo local',
            input: 'select',
            inputOptions: inputOptions,
            inputPlaceholder: 'Selecciona un local',
            showCancelButton: true,
            confirmButtonText: 'Cambiar',
            cancelButtonText: 'Cancelar',
        })
        if (result.isConfirmed) {
            // Aquí puedes realizar la lógica para cambiar el local del empleado
            const nuevoLocalid = result.value;
            try {
                await updateEmpleado(
                    empleado._id,
                    nuevoLocalid,
                    empleado.schedules,
                    empleado.indentification
                );
                await localSelected(localID);
            } catch (error) {
                console.error('Error al crear empleado:', error);
            }
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center">
                {user.permisos?.locales.ver ? (
                    <div className="col-sm mt-5">
                        <label>Selecciona un Local:</label>
                        <select
                            value={localID}
                            onChange={async (e) => { await localSelected(e.target.value) }}
                            className="form-select"
                        >
                            <option value="" disabled>Seleccionar local</option>
                            {
                                localesDisponibles.map((local, index) => (
                                    < option key={index} value={local._id} >
                                        {local.local}
                                    </option>
                                ))}
                        </select>
                        {user.permisos?.locales.editar && (
                            <button className='main-button mt-2'
                                onClick={async () => await handleCrearLocal()}
                            >
                                Crear Local
                            </button>
                        )}
                        &nbsp; &nbsp;

                        {localID && (<>
                            {user.permisos?.locales.editar && (<>
                                <button
                                    className={`main-button mt-2 ${!localID && localID !== '' ? 'disabled-button' : ''}`}
                                    disabled={!localID}
                                    onClick={handleEditarLocal}
                                >
                                    Editar Local
                                </button>
                                &nbsp; &nbsp;
                                <button className="main-button mt-2" onClick={handleAgregarEmpleado}>
                                    Agregar Empleado
                                </button>
                                &nbsp; &nbsp;
                            </>)}

                            {user.permisos?.locales.eliminar && (
                                <button
                                    className={`main-close-btn  ${!localID && localID !== '' ? 'disabled-button' : ''}`}
                                    disabled={!localID && user.permisos?.locales.eliminar}
                                    onClick={() => handleEliminateLocal(localID)}
                                >
                                    Eliminar Local
                                </button>
                            )}
                            <div className="table-responsive mt-5">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Empleado</th>
                                            {["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"].map((day, index) =>
                                                (<th key={index}>{day}</th>))}
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {user.permisos?.locales.ver ? (
                                            localID ? (
                                                localesConEmpleados.map((empleado, index) => (
                                                    <tr key={index}>
                                                        <td>{empleado.indentification}</td>
                                                        {empleado.schedules && empleado.schedules.map((horario, index) => (
                                                            <td key={index}>{horario.isClosed ? "No disponible" : horario.opening + " - " + horario.closing}</td>
                                                        ))}
                                                        <td>
                                                            {user.permisos?.locales.editar && (
                                                                <button className='main-button' onClick={async () => await handleChangeLocal(empleado)}>Cambiar Local
                                                                </button>)}
                                                        </td>
                                                        <td>
                                                            {user.permisos?.locales.editar && (
                                                                <button className='btn btn-danger' onClick={async () => await handleQuitarEmpleado(empleado._id)}>Quitar Empleado</button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={3}>Selecciona un local para ver los empleados.</td>
                                                </tr>
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={3}>No tienes permisos</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>)}
                    </div>
                ) : (<div className='col-sm-5 mt-5'><p>No tienes Permisos</p></div>)}
            </div>
        </div >
    );
}
