import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom"

import ThirdStepModify from './ThirdStepModify';
import "./Styles2.css"
import ResumenModify from "./ResumenModify"
import api from "../../api/api"
import FinalResumen from './FinalResumen';



function ChangeData(props) {

    const [employee, setEmployee] = useState('')
    const navigate = useNavigate();
    const [hora, setHora] = useState('')
    const [horaNew, setHoraNew] = useState('')
    const [newFecha, setNewFecha] = useState('')
    const [horaFinal, setHoraFinal] = useState('')
    const [pasoActual, setPasoActual] = useState(1);
    const [localId, setlocalId] = useState('')

    const [datosPersonales, setDatosPersonales] = useState({
        nombre: '',
        correo: '',
        numero: '',
        genero: '',
    });
    const [servicios, setServicios] = useState([]);
    const [duracion, setDuracion] = useState(0);
    const [isWorkoutTeensChecked, setIsWorkoutTeensChecked] = useState(false);
    const timeWorkoutTeens = 15;

    const [id, setId] = useState("")

    const { token } = useParams()

    useEffect(() => {
        checkToken()

    }, [])

    const checkToken = async () => {
        try {
            const body = {
                token: token
            }
            const response = await api.post(`/bookingToken`, body)
            setDatosPersonales({
                nombre: response.data.booking.client.name,
                correo: response.data.booking.client.email,
                numero: response.data.booking.client.number,
                genero: response.data.booking.client.gender
            })
            setInfo({
                local: response.data.booking.local.name,
                local_id: response.data.booking.local._id,
                fecha: response.data.booking.date,
                hora: response.data.booking.hour
            })


            setId(response.data.booking._id)

            setDuracion(response.data.booking.duration)

            setServicios(response.data.services)

            setIsWorkoutTeensChecked(response.data.booking.isWorkoutTeensChecked)

        } catch (error) {

            console.error("error", error)
            navigate("*")
        }

    }

    const [info, setInfo] = useState({
        local: '',
        local_id: '',
        fecha: '',
        hora: ''
    })


    const handleSiguiente = () => {
        setPasoActual(pasoActual + 1);
    };

    const handleAnterior = () => {
        setPasoActual(pasoActual - 1);
    };






    return (
        <div className='container-fluid justify-content-center align-items-center mt-5' style={{ minHeight: '100vh' }}>
            <div className='row justify-content-center align-items-center'>

                {pasoActual === 1 && (

                    <ResumenModify
                        servicios={servicios}
                        info={info}
                        onNext={handleSiguiente}
                        duracion={duracion}
                        isWorkoutTeensChecked={isWorkoutTeensChecked}
                        timeWorkoutTeens={timeWorkoutTeens}
                    />

                )}

                {pasoActual === 2 && (
                    < ThirdStepModify
                        setInfo={setInfo}
                        info={info}
                        onNext={handleSiguiente}
                        duracion={duracion}
                        setHora={setHora}
                        setHoraFinal={setHoraFinal}
                        setlocalId={setlocalId}
                        setEmployee={setEmployee}
                        setHoraNew={setHoraNew}
                        setNewFecha={setNewFecha}
                        onPrev={handleAnterior}
                    />

                )}

                {pasoActual === 3 && (
                    < FinalResumen
                        servicios={servicios}
                        info={info}
                        datosPersonales={datosPersonales}
                        duracion={duracion}
                        localId={localId}
                        horaFinal={horaFinal}
                        hora={hora}
                        id={id}
                        employee={employee}
                        horaNew={horaNew}
                        newFecha={newFecha}
                        onPrev={handleAnterior}
                        isWorkoutTeensChecked={isWorkoutTeensChecked}
                        timeWorkoutTeens={timeWorkoutTeens}
                    />

                )}
            </div>
        </div>
    );
}

export default ChangeData;
