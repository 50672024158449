import React, { useState, useRef, useEffect, useContext } from 'react';
import Sidebar from '../../Components/Sidebar';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import validator from 'validator';
import api from '../../../api/api';
import { AuthContext } from '../../../Context/AuthContext';
import { mostrarMensajeAlerta } from '../../../util/alet';

const permissionSections = [
    {
        title: 'Locales',
        category: 'locales',
    },
    {
        title: 'Empleados',
        category: 'empleados',
    },
    {
        title: 'Citas',
        category: 'citas',
    },
    {
        title: 'Servicios',
        category: 'servicios',
    },
    {
        title: 'Contactos',
        category: 'contactos',
    },
    {
        title: 'Recordatorios',
        category: 'recordatorios',
    }
]

export default function Perfil() {
    const [usuario, setUsuario] = useState(null);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [nombre, setNombre] = useState('');
    const [perfil, setPerfil] = useState('Administrador');
    const [correo, setCorreo] = useState('');
    const [password, setPassword] = useState('');
    const [repetirPassword, setRepetirPassword] = useState('');
    const [errors, setErrors] = useState({})
    const [usuarios, setUsuarios] = useState([]);
    const [perfiles, setPerfiles] = useState([]);
    const [usuarioEdicion, setUsuarioEdicion] = useState(null);
    const { user } = useContext(AuthContext);

    // Obtener la lista de usuarios desde la API
    async function fetchUsuarios() {
        try {
            const response = await api.get('/users');
            if (response.status === 200) {
                setUsuarios(response.data.users);
            }
        } catch (error) {
            console.error('Error al obtener la lista de usuarios:', error);
        }
    }

    // Obtener la lista de perfiles desde la API
    async function fetchPerfiles() {
        try {
            const response = await api.get('/profile');
            if (response.status === 200 || response.status === 204) {
                setPerfiles(response.data.perfiles);
            }
        } catch (error) {
            console.error('Error al obtener la lista de perfiles:', error);
        }
    }

    useEffect(() => {
        fetchUsuarios();
        fetchPerfiles();
    }, []);

    const nombreRef = useRef(null);
    const perfilRef = useRef(null);
    const correoRef = useRef(null);
    const passwordRef = useRef(null);
    const repetirPasswordRef = useRef(null);

    const validarFormulario = () => {
        const errores = {};

        if (!nombre) {
            errores.nombre = 'El nombre de usuario es obligatorio';
        }

        if (!perfil) {
            errores.perfil = 'El perfil es obligatorio';
        }

        if (!correo || !validator.isEmail(correo)) {
            errores.correo = 'Ingrese un correo electrónico válido';
        }

        if (!password || password.length < 6) {
            errores.password = 'La contraseña debe tener al menos 6 caracteres';
        }

        if (password !== repetirPassword) {
            errores.repetirPassword = 'Las contraseñas no coinciden';
        }

        return errores;
    };

    const handleCrearUsuario = () => {
        // Validar el formulario
        const errores = validarFormulario();

        if (Object.keys(errores).length === 0) {
            // Los datos son válidos, crear el usuario
            const nuevoPerfil = perfiles.find((p) => p.name === perfil);
            const nuevoUsuario = {
                name: nombre,
                perfil,
                email: correo,
                password,
                // Asignar los permisos del perfil al usuario
                permisos: nuevoPerfil.permisos || {},
            };

            // Enviar los datos del nuevo usuario al servidor
            api.post('/user/create', nuevoUsuario)
                .then((response) => {
                    if (response.status === 200) {
                        // Actualizar la lista de usuarios con la respuesta del servidor
                        setUsuarios(response.data.users);

                        // Limpiar los campos del formulario
                        setNombre('');
                        setPerfil('Administrador');
                        setCorreo('');
                        setPassword('');
                        setRepetirPassword('');

                        // Cerrar el modal
                        cerrarModal();
                        fetchUsuarios();
                        mostrarMensajeAlerta("success", "Usuario Creado", 'El usuario ha sido creado exitosamente', false);
                    } else {
                        console.error('Error al crear el usuario:', response.data.message);
                        mostrarMensajeAlerta("error", "Error al crear usuario", 'Hubo un error al crear el usuario', true);
                    }
                })
                .catch((error) => {
                    console.error("error", error.response.data)
                    mostrarMensajeAlerta("error", "Error al crear usuario", error.response.data.message, false);
                    console.error('Error al crear el usuario:', error);
                });
        } else {
            // Mostrar mensajes de error
            setErrors(errores);
        }
    };
    const handleCancelarEdicion = () => {
        // Restaurar el estado original del usuario
        setUsuario(usuarioEdicion);
        setModoEdicion(false);
    };

    const abrirModal = () => {
        setShowModal(true);
    };

    const cerrarModal = () => {
        setShowModal(false);
    };

    const updateUsuario = () => {
        api.put(`/user/${usuario._id}`, usuario)
            .then((response) => {
                if (response.status === 200) {
                    mostrarMensajeAlerta("success", "Usuario Actualizado", '', true);
                    setModoEdicion(false);
                    fetchUsuarios();
                    setUsuario(null);
                }
            })
            .catch((error) => {
                console.error('Error al actualizar usuario:', error);
            });
    };

    const handleEliminarUsuario = async (id) => {
        // Mostrar una alerta de confirmación
        const confirmacion = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el usuario permanentemente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        });

        // Si el usuario confirma, llamamos a la API para eliminar el usuario
        if (confirmacion.isConfirmed) {
            try {
                const response = await api.delete(`/user/${id}`);
                // Aquí puedes manejar la respuesta de la API, por ejemplo, mostrar un mensaje de éxito
                if (response.status === 200) {
                    mostrarMensajeAlerta("success", "Eliminado", 'El usuario ha sido eliminado', false);
                    setUsuario(null);
                    fetchUsuarios();
                } else {
                    mostrarMensajeAlerta("error", "Error", 'No se pudo eliminar el usuario', false);
                }
            } catch (error) {
                mostrarMensajeAlerta("error", "Error", error.error ? error.error : 'No se pudo eliminar el usuario', false);
            }
        }
    };



    const handleCheckboxChange = (permisoCategoria, valor) => {
        setUsuario((prevUsuario) => {
            const [categoria, permiso] = permisoCategoria.split('.');
            return {
                ...prevUsuario,
                permisos: {
                    ...prevUsuario.permisos,
                    [categoria]: {
                        ...prevUsuario.permisos[categoria],
                        [permiso]: valor,
                    },
                },
            };
        });
    };

    const changeProfile = (perfilSeleccionado) => {
        const nuevoPerfil = perfiles.find((p) => p.name === perfilSeleccionado);

        setUsuario({
            ...usuario,
            perfil: perfilSeleccionado,
            permisos: nuevoPerfil.permisos,
        });
    };


    return (
        <div className="container">
            <div className="row mt-3">
                <div className="col-lg-4 col-sm-12">
                    <h1>Configuraciones</h1>
                    <p>Bienvenido/a, {user.name}</p>
                </div>
                <div className="col-lg-3 col-sm-9 text-right">
                    <a href="/administrar-libro-sugerencias-reclamaciones/1" className="btn btn-primary">Administrar libro sugerencias y reclamaciones</a>
                </div>
                <div className="col-lg-3 col-sm-9 text-right">
                    <a href="/administrarHorarios" className="btn btn-primary">Administar Horario Empleado</a>
                </div>
                <div className="col-lg-2  col-sm-3 text-right">
                    <a href="/perfiles" className="btn btn-primary">Perfiles</a>
                </div>
                <div className={usuario ? " col-md-6" : " col-md-12"}>
                    <table className={usuario ? "table col-md-6" : "table col-md-12"}>
                        <thead>
                            <tr>
                                <th>Usuarios</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <button
                                        type="button"
                                        className="main-button mt-3"
                                        onClick={abrirModal}
                                    >
                                        Crear Nuevo Usuario
                                    </button>
                                </td>
                            </tr>
                            {usuarios && usuarios.map((user) => (
                                <tr
                                    key={user._id}
                                    onClick={() => {
                                        setUsuario(user)
                                        setUsuario(prevUser => ({ ...prevUser, password: "" }));
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    className={usuario && usuario._id === user._id ? 'table-active' : ''}
                                >
                                    <td>{user.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {usuario && (
                    <div className='col-md-6'>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h2 className="card-title">{usuario.name}</h2>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setUsuario(null)}
                                >
                                    Cerrar Panel
                                </button>
                            </div>
                            <div className="card-body">
                                {modoEdicion ? (

                                    <div className="card-body" >
                                        <h5 className="mb-3">Editar Usuario</h5>
                                        <div className="form-group">
                                            <label htmlFor="nombre">Nombre de Usuario</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="nombre"
                                                placeholder="Nombre de Usuario"
                                                value={usuario.name}
                                                onChange={(e) => setUsuario({ ...usuario, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="correo">Correo Electrónico</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="correo"
                                                placeholder="Correo Electrónico"
                                                value={usuario.email}
                                                onChange={(e) => setUsuario({ ...usuario, email: e.target.value })}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="correo">Contraseña</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="password"
                                                placeholder="Nueva contraseña"
                                                value={usuario.password}
                                                onChange={(e) => setUsuario({ ...usuario, password: e.target.value })}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="perfil">Perfil</label>
                                            <select
                                                className={`form-control ${errors.perfil ? 'is-invalid' : ''}`}
                                                id="perfil"
                                                value={usuario.perfil}
                                                onChange={(e) => changeProfile(e.target.value)}
                                                ref={perfilRef}
                                            >
                                                {perfiles.map((p) => (
                                                    <option key={p._id} value={p.name}>
                                                        {p.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.perfil && <div className="invalid-feedback">{errors.perfil}</div>}
                                        </div>
                                        <h5 className="mb-3">Editar Permisos</h5>
                                        <div className="card mb-3">
                                            {permissionSections.map((section, index) => (
                                                <div key={index}>
                                                    <h6>{section.title}</h6>
                                                    {Object.keys(usuario.permisos[section.category]).map((key) => (
                                                        <div className="form-check form-check-inline" key={key}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={usuario.permisos[section.category][key]}
                                                                onChange={(e) => handleCheckboxChange(`${section.category}.${key}`, e.target.checked)}
                                                            />
                                                            <label className="form-check-label">{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button className="btn btn-success mr-2" onClick={updateUsuario}>
                                                Actualizar
                                            </button>
                                            <button className="btn btn-light" onClick={handleCancelarEdicion}>
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <p className="card-text">{usuario.email}</p>
                                        <p className="card-text">{usuario.perfil}</p>
                                        <h5>Permisos</h5>
                                        {permissionSections && permissionSections.map((section, index) => (
                                            <div key={index}>
                                                {usuario.permisos[section.category].ver && <h6>{section.title}</h6>}
                                                <div class="row">
                                                    {usuario.permisos[section.category].ver && Object.keys(usuario.permisos[section.category]).map((key) => (
                                                        <p class="col-md-2" key={key}>
                                                            {usuario.permisos[section.category][key] && key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                        {usuario.email !== user.email && (
                                            <button
                                                className="btn btn-success"
                                                onClick={() => { setModoEdicion(true); setUsuarioEdicion(usuario) }}
                                            >
                                                Editar
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="card-footer text-center">
                                <button
                                    className="btn btn-danger"
                                    onClick={() => handleEliminarUsuario(usuario._id)}
                                >
                                    Eliminar Usuario
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal show={showModal} onHide={cerrarModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Crear Nuevo Perfil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre de Usuario</label>
                            <input
                                type="text"
                                className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
                                id="nombre"
                                placeholder="Nombre de Usuario"
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                                ref={nombreRef}
                            />
                            {errors.nombre && <div className="invalid-feedback">{errors.nombre}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="correo">Correo Electrónico</label>
                            <input
                                type="email"
                                className={`form-control ${errors.correo ? 'is-invalid' : ''}`}
                                id="correo"
                                placeholder="Correo Electrónico"
                                value={correo}
                                onChange={(e) => setCorreo(e.target.value)}
                                ref={correoRef}
                            />
                            {errors.correo && <div className="invalid-feedback">{errors.correo}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="perfil">Perfil</label>
                            <select
                                className={`form-control ${errors.perfil ? 'is-invalid' : ''}`}
                                id="perfil"
                                value={perfil}
                                onChange={(e) => setPerfil(e.target.value)}
                                ref={perfilRef}
                            >
                                {perfiles.map((p) => (
                                    <option key={p._id} value={p.name}>
                                        {p.name}
                                    </option>
                                ))}
                            </select>
                            {errors.perfil && <div className="invalid-feedback">{errors.perfil}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Contraseña</label>
                            <input
                                type="password"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                id="password"
                                placeholder="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                ref={passwordRef}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="repetirPassword">Repetir Contraseña</label>
                            <input
                                type="password"
                                className={`form-control ${errors.repetirPassword ? 'is-invalid' : ''}`}
                                id="repetirPassword"
                                placeholder="Repetir Contraseña"
                                value={repetirPassword}
                                onChange={(e) => setRepetirPassword(e.target.value)}
                                ref={repetirPasswordRef}
                            />
                            {errors.repetirPassword && <div className="invalid-feedback">{errors.repetirPassword}</div>}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-light"
                        onClick={cerrarModal}
                    >
                        Cancelar
                    </button>
                    <button
                        className="main-button"
                        onClick={handleCrearUsuario}
                    >
                        Crear usuario
                    </button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}