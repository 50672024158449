import React, { useState, useEffect } from 'react';
import './Styles.css';
import api from '../../api/api';
import { AiOutlineCheck } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

function SecondStep(props) {
    const {
        info,
        setInfo,
        servicios,
        setServicios,
        onNext,
        onPrev,
        gender,
        isWorkoutTeensChecked,
        setIsWorkoutTeensChecked,
        timeWorkoutTeens
    } = props;

    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("Facial");
    const [duracionTotal, setDuracionTotal] = useState(info.duration || 0);
    const [serviciosSeleccionados, setServiciosSeleccionados] = useState(servicios);
    const [corporal, setCorporal] = useState([]);
    const [facial, setFacial] = useState([]);
    const [bronceado, setBronceado] = useState([]);
    const [combos, setCombos] = useState([]);


    useEffect(() => {
        const getServices = async () => {
            try {
                const response = await api.get('/service');
                const categorias = {
                    Facial: "Facial",
                    Corporal: "Corporal",
                    Bronceado: "Bronceado",
                    Combo: "Combo",
                };

                const serviciosPorCategoria = response.data.services.reduce((acc, service) => {
                    if (service.gender === gender && categorias.hasOwnProperty(service.type)) {
                        acc[categorias[service.type]].push(service);
                    }
                    return acc;
                }, {
                    Facial: [],
                    Corporal: [],
                    Bronceado: [],
                    Combo: []
                });
                console.log(serviciosPorCategoria.Combos);

                setFacial(serviciosPorCategoria.Facial);
                setCorporal(serviciosPorCategoria.Corporal);
                setBronceado(serviciosPorCategoria.Bronceado);
                setCombos(serviciosPorCategoria.Combo)
            } catch (error) {
                console.error(error);
            }
        };
        getServices();
    }, [gender]);

    const handleCategoriaChange = (e) => {
        setCategoriaSeleccionada(e.target.value);
    };

    const handleAgregarServicio = (servicio) => {
        setServiciosSeleccionados((prevServicios) => {
            const servicioIndex = prevServicios.findIndex((s) => s._id === servicio._id);

            if (servicioIndex !== -1) {
                return prevServicios.filter((s) => s._id !== servicio._id);
            } else {
                return [...prevServicios, servicio];
            }
        });
    };

    useEffect(() => {
        let nuevaDuracionTotal = serviciosSeleccionados.reduce((total, service) => total + service.duration, 0) + 5; // Agrega 5 minutos por protocolo
        if (isWorkoutTeensChecked) {
            nuevaDuracionTotal += timeWorkoutTeens;
        }
        setDuracionTotal(nuevaDuracionTotal);
        setInfo({
            ...info,
            duration: nuevaDuracionTotal,
        });
        setServicios(serviciosSeleccionados);
    }, [serviciosSeleccionados, setInfo, setServicios, info, isWorkoutTeensChecked, timeWorkoutTeens]);

    const handleCheckboxChange = (event) => {
        setIsWorkoutTeensChecked(event.target.checked);
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Sabemos lo importante que es empezar a cuidarte, y parte de tu cuidado es eliminar ese molesto vello que empieza a ser visible en algunas zonas de tu cuerpo, por eso hemos diseñado un servicio pensado justamente en ti. Personal altamente capacitado te recibirá a ti y a tu acompañante, explicándoles el paso a paso del proceso de depilación y desarrollándolo con paciencia y cuidado para ofrecerte la mejor experiencia posible. Cada cliente Workout Teens cuenta con kit de atención para que puedas disfrutar de esta experiencia.
        </Tooltip>
    );

    return (
        <div className="row justify-content-center">
            <div className="col-sm-6">
                <h2>Paso 2: Servicios</h2>
                <div>
                    <span className='p-0 m-0'>
                        <Form>
                            <Form.Check
                                type="checkbox"
                                label="Workout Teens"
                                checked={isWorkoutTeensChecked}
                                onChange={handleCheckboxChange}
                                inline
                            />
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                                    <BsQuestionCircle size={20} />
                                </span>
                            </OverlayTrigger>
                        </Form>
                    </span>
                    <h5>Selecciona una categoría:</h5>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="categoria"
                                value="Facial"
                                checked={categoriaSeleccionada === "Facial"}
                                onChange={handleCategoriaChange}
                            />
                            &nbsp;
                            Depilación Facial
                        </label> &nbsp; &nbsp;
                        <label>
                            <input
                                type="radio"
                                name="categoria"
                                value="Corporal"
                                checked={categoriaSeleccionada === "Corporal"}
                                onChange={handleCategoriaChange}
                            />
                            &nbsp;
                            Depilación Corporal
                        </label> &nbsp; &nbsp;
                        <label>
                            <input
                                type="radio"
                                name="categoria"
                                value="Bronceado"
                                checked={categoriaSeleccionada === "Bronceado"}
                                onChange={handleCategoriaChange}
                            />
                            &nbsp;
                            Bronceado
                        </label>&nbsp; &nbsp;
                        <label>
                            <input
                                type="radio"
                                name="combos"
                                value="Combos"
                                checked={categoriaSeleccionada === "Combos"}
                                onChange={handleCategoriaChange}
                            />
                            &nbsp;
                            Combos
                        </label>&nbsp; &nbsp;
                    </div>
                    <label htmlFor="categoria">Seleccionar servicio:</label>
                    <div className='mt-2 form-group'>
                        <div >
                            <div className="scrollable-table">
                                <table className="table">
                                    <tbody>
                                        {categoriaSeleccionada === 'Facial' && facial.map((servicio) => (
                                            <tr key={servicio._id}>
                                                <td className={`main-close3 ${serviciosSeleccionados.find(service => service.name === servicio.name) ? 'selected' : ''}`}
                                                    onClick={() => handleAgregarServicio(servicio)}>
                                                    {servicio.name}
                                                    {" "}
                                                    {serviciosSeleccionados.find(service => service.name === servicio.name) && <AiOutlineCheck style={{ color: 'green' }} />}
                                                </td>
                                            </tr>
                                        ))}
                                        {categoriaSeleccionada === 'Corporal' && corporal.map((servicio) => (
                                            <tr key={servicio._id}>
                                                <td className={`main-close3 ${serviciosSeleccionados.find(service => service.name === servicio.name) ? 'selected' : ''}`}
                                                    onClick={() => handleAgregarServicio(servicio)}
                                                >
                                                    {servicio.name}
                                                    {" "}
                                                    {serviciosSeleccionados.find(service => service.name === servicio.name) && <AiOutlineCheck style={{ color: 'green' }} />}
                                                </td>
                                            </tr>
                                        ))}
                                        {categoriaSeleccionada === 'Bronceado' && bronceado.map((servicio) => (
                                            <tr key={servicio._id}>
                                                <td
                                                    className={`main-close3 ${serviciosSeleccionados.find(service => service.name === servicio.name) ? 'selected' : ''}`}
                                                    onClick={() => handleAgregarServicio(servicio)}
                                                >
                                                    {servicio.name}
                                                    {" "}
                                                    {serviciosSeleccionados.find(service => service.name === servicio.name) && <AiOutlineCheck style={{ color: 'green' }} />}
                                                </td>
                                            </tr>
                                        ))}
                                        {categoriaSeleccionada === 'Combos' && combos.map((servicio) => (
                                            <tr key={servicio._id}>
                                                <td
                                                    className={`main-close3 ${serviciosSeleccionados.find(service => service.name === servicio.name) ? 'selected' : ''}`}
                                                    onClick={() => handleAgregarServicio(servicio)}
                                                >
                                                    {servicio.name}{" "}
                                                    {serviciosSeleccionados.find(service => service.name === servicio.name) && <AiOutlineCheck style={{ color: 'green' }} />}
                                                    <div style={{ fontSize: 'small', fontStyle: 'italic' }}>
                                                        {servicio.description}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <label>Servicios seleccionados</label>
                            <div className="scrollable-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Duración</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviciosSeleccionados.map((servicio) => (
                                            <tr key={servicio._id}>
                                                <td>{servicio.name}</td>
                                                <td>{servicio.duration} minutos</td>
                                                <td>
                                                    <button
                                                        className="main-close2"
                                                        onClick={() => handleAgregarServicio(servicio)}
                                                    >
                                                        {serviciosSeleccionados.includes(servicio) ? 'Eliminar' : 'Agregar'}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {serviciosSeleccionados.length === 0 && (
                    <p className="text-danger mt-2">Por favor, selecciona un servicio.</p>
                )}
                <p>Duración total: {duracionTotal} minutos</p>
                <p>Se añaden 5 minutos por protocolo</p>
                <button className="main-button mt-2" onClick={onPrev}>
                    Anterior
                </button>
                &nbsp; &nbsp;
                <button
                    className="main-close mt-2"
                    onClick={onNext}
                    disabled={serviciosSeleccionados.length === 0}
                >
                    Siguiente
                </button>
            </div>
        </div>
    );
}

export default SecondStep;
