import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import instance from '../../api/api';
import DownloadIcon from '@mui/icons-material/Download';
import { AuthContext } from '../../Context/AuthContext';

const ExcelDownloadModal = ({ idLocal }) => {
    const { user } = useContext(AuthContext);

    const [show, setShow] = useState(false);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [error, setError] = useState('');


    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setFechaInicio(today);
        setFechaFin(today);
    }, []);

    const handleClose = () => {
        setShow(false);
        setError('');
    };

    const handleShow = () => setShow(true);

    const handleDateChange = (dateType, dateValue) => {
        if (dateType === 'fechaInicio' && new Date(dateValue) > new Date(fechaFin)) {
            setError('La fecha de inicio no puede ser posterior a la fecha de fin.');
        }
        else if (dateType === 'fechaFin' && new Date(dateValue) < new Date(fechaInicio)) {
            setError('La fecha de fin no puede ser anterior a la fecha de inicio.');
        } else {
            setError('');
        }

        if (dateType === 'fechaInicio') {
            setFechaInicio(dateValue);
        } else if (dateType === 'fechaFin') {
            setFechaFin(dateValue);
        }
    };

    const handleDownload = async (e) => {
        e.preventDefault(); 

        const diffMeses = (new Date(fechaFin) - new Date(fechaInicio)) / (1000 * 60 * 60 * 24 * 30);
        if (diffMeses > 3) {
            setError('El rango de fechas no puede exceder los 3 meses.');
            return;
        }

        const fechaActual = new Date();
        if (new Date(fechaInicio) > fechaActual) {
            setError('La fecha de inicio no puede ser posterior a la fecha actual.');
            return;
        }

        try {
            const response = await instance.post('/download-report', {
                fechaInicio,
                fechaFin,
                idLocal
            }, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'reservas.xlsx');
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            handleClose();
        } catch (error) {
            console.error('Error al descargar el archivo Excel:', error);
            setError('Error al descargar el archivo Excel. Por favor, inténtalo de nuevo más tarde.');
        }
    };

    return (
        <>
            { (idLocal  && user?.permisos?.citas?.editar )&& (
                <>
                    <button type="button" className="btn " onClick={handleShow} >
                        <DownloadIcon />
                    </button>
                </>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Descargar Reporte Excel</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleDownload}>
                    <Modal.Body>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form.Group controlId="fechaInicio">
                            <Form.Label>Fecha de Inicio</Form.Label>
                            <Form.Control type="date" value={fechaInicio} onChange={(e) => handleDateChange('fechaInicio', e.target.value)} max={new Date().toISOString().split('T')[0]} />
                        </Form.Group>
                        <Form.Group controlId="fechaFin">
                            <Form.Label>Fecha de Fin</Form.Label>
                            <Form.Control type="date" value={fechaFin} onChange={(e) => handleDateChange('fechaFin', e.target.value)} max={new Date().toISOString().split('T')[0]} />
                        </Form.Group>
                        <small className="text-muted">Solo puedes seleccionar un máximo de 3 meses.</small>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button variant="primary" type='submit'>
                            Descargar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default ExcelDownloadModal;
