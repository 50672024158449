import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function Header() {
    return (
        <div className='container-fluid' style={{ position: 'fixed', top: 0, left: 0, width: '100%', background: 'white', zIndex: 100 }}>
            <div className='row justify-content-end align-items-left header'>
                <div className='col-sm' style={{ minHeight: '40px' }}>
                    { /*   <FontAwesomeIcon className="icon" icon={faFacebook} />
            <FontAwesomeIcon className="icon" icon={faInstagram} />
            <FontAwesomeIcon className="icon" icon={faWhatsapp} />*/}
                </div>
            </div>
        </div>
    )
}
