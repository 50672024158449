import { useState, useEffect } from 'react';

export const useIdleTimer = (timeout) => {
    const [isIdle, setIsIdle] = useState(false);
    let timer;

    const resetTimer = () => {
        const currentTime = Date.now();
        localStorage.setItem('lastActiveTime', currentTime.toString());
        clearTimeout(timer);
        setIsIdle(false);
        timer = setTimeout(() => setIsIdle(true), timeout);
    };

    useEffect(() => {
        const lastActiveTime = localStorage.getItem('lastActiveTime');
        if (lastActiveTime) {
            const elapsedTime = Date.now() - parseInt(lastActiveTime, 10);
            if (elapsedTime >= timeout) {
                setIsIdle(true);
                return;
            } else {
                timer = setTimeout(() => setIsIdle(true), timeout - elapsedTime);
            }
        } else {
            localStorage.setItem('lastActiveTime', Date.now().toString());
            timer = setTimeout(() => setIsIdle(true), timeout);
        }

        const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        return () => {
            clearTimeout(timer);
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
        };
    }, [timeout]);

    return isIdle;
};
