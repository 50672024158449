import React, { useState, useEffect, useCallback } from 'react';
import api from "../../api/api";
import { FaWhatsapp } from "react-icons/fa";
import { mostrarMensajeAlerta } from '../../util/alet';
import { getFechaActual } from '../../util/util';
import Spinner from '../../components/Spinner';

const memoizedLocales = {
    data: null,
    fetch: async () => {
        if (!memoizedLocales.data) {
            const response = await api.get("/local");
            if (response.status === 200) {
                memoizedLocales.data = response.data.locals;
            } else {
                throw new Error('Error fetching locales');
            }
        }
        return memoizedLocales.data;
    }
};

function ThirdStep(props) {
    const { onNext, onPrev, info, setInfo, duracion } = props;
    const [fechaSeleccionada, setFechaSeleccionada] = useState(info.fecha);
    const [horaSeleccionada, setHoraSeleccionada] = useState("");
    const [localesDisponibles, setLocalesDisponibles] = useState([]);
    const [horasDisponibles, setHorasDisponibles] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [localId, setLocalId] = useState(info.local_id);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const handelHour = useCallback((horario) => {
        if (horario) {
            setHoraSeleccionada(horario.hour);
            setInfo({
                ...info,
                hora: horario.hour,
                employeeID: horario.employees[0],
                horaFinal: horario.hour_end
            });
        } else {
            setHoraSeleccionada("");
            setInfo({
                ...info,
                hora: '',
                employeeID: '',
                horaFinal: ''
            });
        }
    }, [setHoraSeleccionada, setInfo, info]);

    const handleLocal = useCallback((selectedValue) => {
        const selectedLocal = localesDisponibles.find((local) => local._id === selectedValue);
        if (selectedLocal) {
            setLocalId(selectedValue);
            handelHour();
            setInfo({
                ...info,
                local: selectedLocal.local,
                local_id: selectedLocal._id,
            });
        }
    }, [localesDisponibles, setLocalId, handelHour, setInfo, info]);

    useEffect(() => {
        async function fetchLocales() {
            try {
                const locals = await memoizedLocales.fetch();
                setLocalesDisponibles(locals);
                if (info && locals && !info.local_id) {
                    const defaultLocalId = locals[0]._id;
                    setLocalId(defaultLocalId);
                    setInfo({
                        ...info,
                        local: locals[0].local,
                        local_id: defaultLocalId,
                    });
                }
            } catch (error) {
                setHorasDisponibles([]);
                handleLocal([]);
                console.error("Error al obtener la lista de locales:", error);
            }
        }
        fetchLocales();
    }, [setInfo, info, handleLocal]);

    useEffect(() => {
        async function fetchHorasDisponibles() {
            setLoadingSpinner(true);
            setHorasDisponibles([]);
            if (localId && fechaSeleccionada) {
                if (fechaSeleccionada >= getFechaActual()) {
                    const body = {
                        fechaSeleccionada,
                        id: localId,
                        duration: info.duration,
                    };
                    try {
                        const response = await api.post("/schedule", body);
                        if (response.status === 200) {
                            setHorasDisponibles(response.data.horarioDisponible);
                        }
                    } catch (error) {
                        setHorasDisponibles([]);
                        console.error("Error al obtener las horas disponibles:", error);
                    }
                } else {
                    const errors = {};
                    errors.fecha = "La fecha seleccionada debe ser mayor o igual a la fecha actual";
                    setFormErrors(errors);
                }
            }
            setLoadingSpinner(false);
        }
        fetchHorasDisponibles();
    }, [fechaSeleccionada, localId, duracion]);

    useEffect(() => {
        const errors = {};
        if (!localId) {
            errors.local = "Selecciona un local";
        }
        if (!fechaSeleccionada) {
            errors.fecha = "Selecciona una fecha";
        }
        if (!horaSeleccionada) {
            errors.hora = "Selecciona una hora";
        }
        setFormErrors(errors);
    }, [localId, fechaSeleccionada, horaSeleccionada]);

    const handleDate = (nuevaFecha) => {
        setFechaSeleccionada(nuevaFecha);
        setInfo({ ...info, fecha: nuevaFecha });
        handelHour();
    };

    return (
        <div className="row justify-content-center">
            <div className='col-sm-6'>
                <h2>Paso 3: Selección de Local, Fecha y Hora</h2>
                <div className='mt-2 form-group'>
                    <label>Seleccione el local</label>
                    <select
                        value={localId}
                        onChange={(e) => handleLocal(e.target.value)}
                        className="form-select"
                    >
                        {localesDisponibles.map((local) => (
                            <option key={local._id} value={local._id}>
                                {local.local}
                            </option>
                        ))}
                    </select>
                    {formErrors.local && <p className="error-message">{formErrors.local}</p>}
                </div>
                <div className='mt-2 form-group'>
                    <label>Seleccione la fecha</label>
                    <input
                        type="date"
                        value={fechaSeleccionada}
                        min={getFechaActual()}
                        aria-required
                        className="form-control"
                        onChange={(e) => handleDate(e.target.value)}
                    />
                    {formErrors.fecha && <p className="error-message">{formErrors.fecha}</p>}
                </div>
                {horasDisponibles.length > 0 ? (
                    <div className='mt-2 form-group'>
                        <label>Seleccione el horario</label>
                        <div className="scrollable-table">
                            <table className="table">
                                <tbody>
                                    {horasDisponibles.map((horario, index) => (
                                        <tr key={index} className={`${horaSeleccionada === horario.hour ? 'opcion-seleccionada' : ''}`}>
                                            <td
                                                onClick={() => handelHour(horario)}
                                                className={`main-close3`}
                                            >
                                                {horario.hour}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <> {loadingSpinner ? <div style={{ maxHeight: "200px" }}><Spinner /> </div> : (localId || fechaSeleccionada) && (<p>No hay horarios disponibles</p>)} </>
                )}
                <span>
                    Si no encuentras un horario disponible escríbenos por WhatsApp <a target="_blank" href='https://wa.me/593999441283?text=Hola%20no%20encuentro%20un%20horario%20disponible%20en%20la%20web'>aquí</a> <FaWhatsapp style={{ color: "green" }} /> .
                </span>

                <div>
                    <button className="main-button mt-2" onClick={onPrev}>Anterior</button> &nbsp; &nbsp;
                    <button
                        className="main-close mt-2"
                        onClick={() => {
                            if (!localId || !horaSeleccionada || !fechaSeleccionada || fechaSeleccionada < getFechaActual()) {
                                mostrarMensajeAlerta("error", "Alerta", "Por favor, llena todos los campos y asegúrate de que la fecha seleccionada sea mayor o igual a la fecha actual", true);
                            } else {
                                setInfo({ ...info, fecha: fechaSeleccionada });                                
                                onNext();
                            }
                        }}
                    >
                        Continuar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ThirdStep;
