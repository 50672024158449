import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'; // Importa los iconos de ordenación
import instance from '../../../api/api';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import SearchBar from '../../../components/SearchBar';
import { convertirFecha, formatFecha } from '../../../util/util';
import { mostrarMensajeAlerta } from '../../../util/alet';
import { AuthContext } from '../../../Context/AuthContext';

const AdminComplaintAndSuggestion = () => {
    const { page } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(parseInt(page) || 1);
    const [contacts, setContacts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchField, setSearchField] = useState('createdAt');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('asc');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);

    // Verifica si el usuario es administrador
    useEffect(() => {
        if (user.perfil !== "Administrador") {
            mostrarMensajeAlerta("error", "Autentificación", 'Lo sentimos, no es un usuario administrador.', true);
            navigate(`/`);
        }
    }, [user]);

    const filterList = [{ key: 'codigo', value: 'Codigo' }, { key: 'nombre', value: 'Nombre' }, { key: 'telefono', value: 'Telefono' }, { key: 'motivo', value: 'Motivo' },]

    useEffect(() => {
        setCurrentPage(parseInt(page) || 1);
    }, [page]);

    useEffect(() => {
        fetchContacts(currentPage, searchField, searchQuery, sortField, sortOrder, itemsPerPage);
    }, [currentPage, searchField, searchQuery, sortField, sortOrder, itemsPerPage]);

    const fetchContacts = async (page, searchField, searchQuery, sortField, sortOrder, limit) => {
        setLoading(true);
        try {
            const response = await instance.get(`/get-complaint-and-suggestion`, {
                params: {
                    page,
                    limit,
                    field: searchField,
                    search: searchQuery,
                    sortField,
                    sortOrder
                }
            });
            setContacts(response.data.results);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error capturar datos", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        navigate(`/administrar-libro-sugerencias-reclamaciones/${page}`);
    };

    const handleSearch = (field, query) => {
        setSearchField(field);
        setSearchQuery(query);
        setCurrentPage(1);
        navigate(`/administrar-libro-sugerencias-reclamaciones/1`);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
        navigate(`/administrar-libro-sugerencias-reclamaciones/1`);
    };

    const renderSortIcon = (field) => {
        if (sortField !== field) return <FaSort />;
        if (sortOrder === 'asc') return <FaSortUp />;
        return <FaSortDown />;
    };

    return (
        <div className="container">
            <h1 className="my-3">Libro de sugerencias y reclamaciones</h1>
            <SearchBar onSearch={handleSearch} filterList={filterList} />
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => handleSort('codigo')}>Codigo {renderSortIcon('codigo')}</th>
                                    <th scope="col" onClick={() => handleSort('nombre')}>Nombre {renderSortIcon('lastName')}</th>
                                    <th scope="col" onClick={() => handleSort('email')}>Email {renderSortIcon('email')}</th>
                                    <th scope="col" onClick={() => handleSort('telefono')}>Número de telefono {renderSortIcon('number')}</th>
                                    <th scope="col" onClick={() => handleSort('motivo')}>Motivo {renderSortIcon('gender')}</th>
                                    <th scope="col" >Mensaje</th>
                                    <th scope="col" >Fecha Cita </th>
                                    <th scope="col" >Hora Cita </th>
                                    <th scope="col" >Colaborador</th>
                                    <th scope="col" onClick={() => handleSort('createdAt')}>Fecha del reaclamo / sugerencia {renderSortIcon('createdAt')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts && contacts.map((contact, index) => (
                                    <tr key={contact._id}>
                                        <th scope="row">{index + 1 + (currentPage - 1) * itemsPerPage}</th>
                                        <td>{contact.codigo}</td>
                                        <td>{contact.nombre}</td>
                                        <td>{contact.email?.toLowerCase()}</td>
                                        <td>{contact.telefono}</td>
                                        <td>{contact.motivo}</td>
                                        <td>{contact.mensaje}</td>
                                        <td>{contact.fechaCita ?  formatFecha(contact.fechaCita ) : 'N/A'}</td>
                                        <td>{contact.horaCita ? contact.horaCita : 'N/A'}</td>
                                        <td>{contact.colaborador ? contact.colaborador : 'N/A'}</td>
                                        <td>{contact.createdAt ?  formatFecha(contact.createdAt ) : 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        itemsPerPage={itemsPerPage}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </>
            )}
        </div>
    );
};

export default AdminComplaintAndSuggestion;
