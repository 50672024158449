import './App.css';
import "./Pages/Home"
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from 'react';
import { AuthContext } from './Context/AuthContext';
import Home from './Pages/Home';
import Login from './Pages/BackOfiice/Login';
import Sidebar from './Pages/Components/Sidebar';
import Booking from './Pages/BackOfiice/Booking/Booking';
import Local from './Pages/BackOfiice/Local/Local';
import Admin from './Pages/BackOfiice/Admin/Admin';
import ChangeDate from "./Pages/ModifyBooking/ChangeDate"
import Header from './Pages/Components/Header';
import Reserve from './Pages/Steps/Reserve';
import DeleteBooking from './Pages/ModifyBooking/DeleteBooking';
import ServiceData from './Pages/BackOfiice/Service/ServiceData';
import CreateServiceData from './Pages/BackOfiice/Service/CreateServiceData';
import EditServiceData from './Pages/BackOfiice/Service/EditServiceData';
import NewProfile from './Pages/BackOfiice/Admin/Perfiles';
import LocalBookingAdmin from './Pages/BackOfiice/Admin/LocalBookingAdmin';
import Employe from './Pages/BackOfiice/employe/Employe';
import EmployeeForm from './Pages/BackOfiice/employe/EmployeForm';
import ForgotPassword from './Pages/BackOfiice/ForgotPassword';
import ResetPassword from './Pages/BackOfiice/ResetPassword';
import BronceadoPage from './Pages/info/BronceadoPage';
import ContactList from './Pages/BackOfiice/contacts/ContactList';
import ReminderList from './Pages/BackOfiice/reminder/ReminderList';
import { useIdleTimer } from './hooks/useIdleTimer';
import { CacheService } from './services/CacheService';
import Book from './Pages/public/Book';
import AdminComplaintAndSuggestion from './Pages/BackOfiice/Admin/AdminComplaintAndSuggestion';
import Dashboard from './Pages/BackOfiice/dashboard/Dashboard';



function App() {
  const idleTime = 4 * 60 * 60 * 1000;/// se borra la cache despues de 4 horas de inactividad
  const isUserIdle = useIdleTimer(idleTime);
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();



  // Verifica si el usuario ha estado inactivo
  useEffect(() => {
    if (isUserIdle) {
      if (user) {
        logout().then(() => {
          navigate('login');
          CacheService.clearCache();
        })
      }
    }
  }, [isUserIdle]);


  if (user && window.location.pathname === "/login") {
    return <Navigate to="/booking" />;
  }

  return (
    <div className="App">
      <div className="wrapper">
        {user && <Sidebar />}
        <div id="content">
          {!user && <Header />}
          <Routes>
            <Route index element={<Home />} />
            <Route path="/reserve" element={<Reserve />} />
            {!user ? (<>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
            </>) : (
              <>
                {user.perfil === "Administrador" && <Route path="/admin" element={<Admin />} />}
                <Route path="/local" element={<Local />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/servicios" element={<ServiceData />} />
                <Route path="/servicios/crearServicio" element={<CreateServiceData />} />
                <Route path="/editarServicio/:id" element={<EditServiceData />} />
                <Route path="/empleados" element={<Employe />} />
                <Route path="/crear-empleado" element={<EmployeeForm />} />
                <Route path="/editar-empleado/:id" element={<EmployeeForm />} />
                {user.perfil === "Administrador" && <Route path="/perfiles" element={<NewProfile />} />}
                {user.perfil === "Administrador" && <Route path="/administrarHorarios" element={<LocalBookingAdmin />} />}
                {user.perfil === "Administrador" && <Route path="/dashboard" element={<Dashboard />} />}
                {user.permisos && user.permisos.contactos?.ver && <Route path="/contacts/:page" element={<ContactList />} />}
                {user.permisos && user.permisos.recordatorios?.ver && <Route path="/reminder/:page" element={<ReminderList />} />}
              </>)}
            <Route path="/modify/:token" element={<ChangeDate />} />
            <Route path="/delete/:token" element={<DeleteBooking />} />
            <Route path="/bronceado" element={<BronceadoPage />} />
            <Route path="/libro-sugerencias-reclamaciones" element={<Book />} />
            <Route path="/administrar-libro-sugerencias-reclamaciones/:page" element={<AdminComplaintAndSuggestion />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
