export class CacheService {
    static clearCache() {
        if ('caches' in window) {
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }

        localStorage.clear();
        sessionStorage.clear();
    }
}
