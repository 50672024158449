import React from 'react'
import Swal from 'sweetalert2';
import api from "../../api/api"
import { useNavigate } from 'react-router-dom';
import { mostrarMensajeAlerta } from '../../util/alet';

export default function FinalResumen(props) {

    const navigator = useNavigate()

    const { info, servicios, onNext, onPrev, datosPersonales, servicio, duracion, localId, horaFinal, hora, id, employee, horaNew, newFecha, isWorkoutTeensChecked, timeWorkoutTeens } = props


    async function onBooking() {
        const body = {
            nombre: datosPersonales.nombre,
            correo: datosPersonales.correo.toLowerCase(),
            numero: datosPersonales.numero,
            genero: datosPersonales.genero,
            servicios: servicios,
            duracion: duracion,
            local_id: localId,
            fecha: newFecha,
            hora: horaNew,
            hora_fin: horaFinal,
            employe: employee
        }
        try {
            Swal.fire({
                position: 'center',
                title: 'Espera un momento...',
                showConfirmButton: false,
                allowOutsideClick: false,
                html: '<div style="color: #e33084; overflow-y: hidden;"><i class="fas fa-circle-notch fa-spin fa-3x"></i></div>',
            })
            const response = await api.put(`/booking/${id}`, body);
            if (response.data.status) {
                Swal.close()
                Swal.fire({
                    icon: 'success',
                    confirmButtonColor: '#e33084',
                    iconHtml: '<img src="https://www.easy-gst.in/wp-content/uploads/2017/07/success-icon-10.png" alt="Icono personalizado" width="98">',
                    title: 'Éxito',
                    text: '¡Se ha creado la agenda te enviaremos un correo!',
                    allowOutsideClick: false,
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigator("/")
                    }
                });
            }
        } catch (error) {
            mostrarMensajeAlerta('error', "Error",
                'Ha ocurrido un error', true);
        }
    }
    return (
        <div>
            <h2>Resumen</h2>
            <div className='row justify-content-center mt-3'>
                <div className='col-sm-5'>
                    <h5>Servicios</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Servicio</th>
                                <th>Duración</th>
                            </tr>
                        </thead>
                        <tbody>
                            {servicios.map((servicio, index) => (
                                <>
                                    <tr key={index}>
                                        <td>{servicio.name}</td>
                                        <td>{servicio.duration} minutos</td>
                                    </tr>
                                    {isWorkoutTeensChecked &&
                                        <tr key={index}>
                                            <td>Workout Teens</td>
                                            <td>{timeWorkoutTeens} minutos</td>
                                        </tr>}
                                </>
                            ))}
                        </tbody>
                    </table>
                    <p>Duración Total: {duracion} minutos</p>
                </div>
                <div className='col-sm-5'>
                    <h5>Local, hora y fecha</h5>
                    <form>
                        <div className="form-group">
                            <label htmlFor="nombre">Local:</label>
                            <input
                                type="text"
                                id="local"
                                name="local"
                                className="form-control"
                                placeholder="local"
                                value={info.local}
                                readOnly
                                style={{ backgroundColor: '#f2f2f2' }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="correo">Fecha:</label>
                            <input
                                type="text"
                                id="fecha"
                                name="fecha"
                                className="form-control"
                                placeholder="fecha"
                                value={newFecha}
                                readOnly // Hace que el campo sea de solo lectura
                                style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="numero">Hora</label>
                            <input
                                type="text"
                                id="hora"
                                name="hora"
                                className="form-control"
                                placeholder="hora"
                                value={horaNew}
                                readOnly // Hace que el campo sea de solo lectura
                                style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                            />
                        </div>
                    </form>
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col-sm-6'>
                    <div className="text-center">
                        <button className="main-close mt-2" onClick={onPrev}>Anterior</button>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className="text-center">
                        <button className="main-button mt-2" onClick={onBooking}>Actualizar</button>
                    </div>
                </div>
            </div>
        </div >
    )

}
