import axios from 'axios';

const instance = axios.create({
    baseURL: "https://booking.workout.com.ec/api/"
    //baseURL: "http://localhost:3004/api/"
});

let isRefreshing = false;
let failedQueue = [];


instance.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('user-token')) {
            config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('user-token');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return instance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const response =  await instance.post('/user/token', {token: localStorage.getItem('refreshToken')});
                localStorage.setItem('user-token', response.data.token);
                localStorage.setItem("refreshToken", response.data.refreshToken)
                originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('user-token');
                return instance(originalRequest);
            } catch (err) {
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        }

        if (error.response.status === 403) {
            // Token de refresco también ha expirado
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default instance;
