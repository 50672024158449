import React, { useEffect, useState } from 'react'
import api from "../../api/api"
import Swal from 'sweetalert2';
import { mostrarMensajeAlerta } from '../../util/alet';
import { modificarOrigen } from '../../util/util';
//import { useNavigate } from 'react-router-dom';



export default function Resume(props) {
    const { servicios, info, datosPersonales, onPrev, onCLose, hora, isWorkoutTeensChecked, timeWorkoutTeens } = props;



    async function onBooking() {
        const body = {
            nombre: datosPersonales.nombre,
            apellido: datosPersonales.apellido,
            correo: datosPersonales.correo?.toLowerCase(),
            numero: datosPersonales.numero,
            genero: datosPersonales.genero,
            origen: modificarOrigen(datosPersonales)?.origen.join(', '),
            empleadoID: info.employeeID,
            servicios: servicios,
            duracion: info.duration,
            local_id: info.local_id,
            fecha: info.fecha,
            hora: info.hora,
            hora_fin: info.horaFinal,
            isWorkoutTeensChecked
        }
        try {
            Swal.fire({
                title: 'Espera un momento...',
                text: 'Estamos procesando tu reserva',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
                didClose: () => {
                    Swal.stopLoading();
                }
            });
            const response = await api.post("/booking", body)
            if (response.data.status) {
                Swal.close()
                Swal.fire({
                    icon: 'success',
                    confirmButtonColor: '#e33084',
                    iconHtml: '<img src="https://www.easy-gst.in/wp-content/uploads/2017/07/success-icon-10.png" alt="Icono personalizado" width="98">', title: 'Agenda',
                    html: `¡Te enviaremos un correo!<br>Se ha agendado una cita en el local:<br><strong>${info.local}</strong><br>Fecha: <strong>${info.fecha}</strong><br>Hora: <strong>${info.hora}</strong><br>Por favor revisa las indicaciones para ir a tus citas<a href="https://booking.workout.com.ec/bronceado"> Aqui</a>
                    `,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        onCLose()
                    }
                });
            }
        } catch (error) {
            console.error(error);
            mostrarMensajeAlerta('error', "Error",
                error.response.data.message || 'Ha ocurrido un error al agendar la cita', true);
        }
    }


    return (
        <div>
            <h2>Resumen</h2>
            <div className='row justify-content-center mt-3'>
                <div className='col-sm-5'>
                    <h5>Informacion Personal:</h5>

                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Nombre</strong>: {datosPersonales.nombre} {datosPersonales.apellido}</li>
                        <li className="list-group-item"><strong>Correo Electrónico:</strong> {datosPersonales.correo}</li>
                        <li className="list-group-item"><strong>Teléfono</strong>: {datosPersonales.numero}</li>
                    </ul>
                    <h5>Servicios Seleccionados:</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Servicio</th>
                                <th>Duración</th>
                            </tr>
                        </thead>
                        <tbody>
                            {servicios.map((servicio, index) => (
                                <>
                                    <tr key={index}>
                                        <td>{servicio.name}</td>
                                        <td>{servicio.duration} minutos</td>
                                    </tr>
                                    {isWorkoutTeensChecked && <tr key={index}>
                                        <td>Workout Teens</td>
                                        <td>{timeWorkoutTeens} minutos</td>
                                    </tr>}
                                </>
                            ))}
                        </tbody>
                    </table>
                    <p>Duración Total: {info.duration} minutos</p>
                </div>
                <div className='col-sm-5'>
                    <h5>Local, hora y fecha</h5>
                    <form>
                        <div className="form-group">
                            <label htmlFor="nombre">Local:</label>
                            <input
                                type="text"
                                id="local"
                                name="local"
                                className="form-control"
                                placeholder="local"
                                value={info.local}
                                readOnly
                                style={{ backgroundColor: '#f2f2f2' }}
                            />

                        </div>
                        <div className="form-group">
                            <label htmlFor="correo">Fecha:</label>
                            <input
                                type="text"
                                id="fecha"
                                name="fecha"
                                className="form-control"
                                placeholder="fecha"
                                value={info.fecha}
                                readOnly
                                style={{ backgroundColor: '#f2f2f2' }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="numero">Hora</label>
                            <input
                                type="text"
                                id="hora"
                                name="hora"
                                className="form-control"
                                placeholder="hora"
                                value={info.hora}
                                readOnly
                                style={{ backgroundColor: '#f2f2f2' }}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-sm-5'>
                    <button className="main-button mt-2" onClick={onPrev}>Anterior</button> &nbsp; &nbsp;
                    <button className="main-close mt-2"
                        onClick={() => { onBooking(); }}
                    >Reservar</button>
                </div>
            </div>
            <div>
            </div>
        </div >
    )
}
