import React, { useState, useEffect, useContext } from 'react';
import instance from '../../../api/api';
import Sidebar from '../../Components/Sidebar';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { mostrarMensajeAlerta } from '../../../util/alet';
import { AuthContext } from '../../../Context/AuthContext';

const Employee = () => {
    const [employees, setEmployees] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [employeesPerPage] = useState(10);
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    const getEmployees = async () => {
        await instance.get('/empleados')
            .then((response) => {
                setEmployees(response.data.empleados);
            })
            .catch((error) => {
                console.error('Error al obtener la lista de empleados: ', error);
            });
    }

    useEffect(() => {
        getEmployees()
    }, []);

    const totalEmployees = employees.length;
    const totalPages = Math.ceil(totalEmployees / employeesPerPage);

    const sortEmployeesByLocal = (employees) => {
        return employees.sort((a, b) => {
            const localA = a.local_id ? a.local_id.local : "";
            const localB = b.local_id ? b.local_id.local : "";
            return localA.localeCompare(localB);
        });
    }

    const sortedEmployees = sortEmployeesByLocal(employees);

    // Función para cambiar la página actual
    const paginate = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    }

    const indexOfLastEmployee = currentPage * employeesPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
    const currentEmployees = sortedEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    const handleDeleteEmployee = (employeeId) => {
        // Muestra un mensaje de confirmación antes de eliminar al empleado
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará al empleado permanentemente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                emliminarEmpleado(employeeId);
            }
        });
    }

    const emliminarEmpleado = async (employeeId) => {
        await instance.delete('/empleados/' + employeeId)
            .then((response) => {
                getEmployees();
                mostrarMensajeAlerta("success", "Empleado eliminado", 'Empleado eliminado', false);
            })
            .catch((error) => {
                let message = "Error al eliminar el Empleado ";
                console.error(error.response);
                if (error.response.status === 404) {
                    if (error.response.data.bookings && error.response.data.bookings.length > 0) {
                        message = "Empleado tiene citas agendadas:\n";

                        // Mostrar hasta dos citas
                        const maxToShow = 2;
                        const bookingsToDisplay = error.response.data.bookings.slice(0, maxToShow);

                        bookingsToDisplay.forEach(booking => {
                            message += `Local: ${booking.local.name}, Fecha: ${booking.date}, Hora: ${booking.hour}\n`;
                        });

                        // Verificar si hay más de dos citas
                        if (error.response.data.bookings.length > maxToShow) {
                            message += "Existen más citas. Soluciona este problema primero.";
                        }
                        message += "Soluciona este problema primero."
                    } else {
                        message = error.response.data?.message;
                    }
                }

                mostrarMensajeAlerta("error", message, '', true);
                console.error('Error al obtener al eliminar empleado: ', error);
            });
    }

    return (
        <div className="container" style={{ zIndex: 0 }}>
            {user && user.permisos && user.permisos?.empleados?.ver ? (
                <div className="row justify-content-center align-items-center">
                    <div className="col-sm mt-5">
                        <h2>Empleados </h2>
                        {user.permisos?.empleados?.eliminar &&
                            <button
                                className="main-button"
                                onClick={() => navigate(`/crear-empleado`)}
                            >
                                Crear Empleado
                            </button>
                        }
                        <input
                            type="text"
                            placeholder="Buscar empleados..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="form-control mb-2"
                        />
                        <div className="table-responsive mt-5">
                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Empleado</th>
                                        <th>Local</th>
                                        {user.permisos?.empleados?.editar && <th></th>}
                                        {user.permisos?.empleados?.eliminar && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentEmployees
                                        .filter((employee) =>
                                            employee.indentification.toLowerCase().includes(search.toLowerCase())
                                        )
                                        .map((employee) => (
                                            <tr key={employee._id}>
                                                <td>{employee.indentification}</td>
                                                <td>{employee.local_id ? employee.local_id.local : "Sin local"}</td>
                                                {user.permisos?.empleados?.editar &&
                                                    <td>
                                                        <button
                                                            className="main-button"
                                                            onClick={() => navigate(`/editar-empleado/${employee._id}`)}
                                                        >
                                                            Editar
                                                        </button>
                                                    </td>
                                                }
                                                {user.permisos?.empleados?.eliminar &&
                                                    <td>
                                                        <button className="btn btn-danger"
                                                            onClick={() => handleDeleteEmployee(employee._id)}
                                                        >Eliminar
                                                        </button>
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <nav>
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => paginate(currentPage - 1)}
                                    >
                                        Anterior
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <li
                                        key={index}
                                        className={`page-item ${index + 1 === currentPage ? 'active active-workout' : ''}`}
                                    >
                                        <button
                                            className="page-link"
                                            onClick={() => paginate(index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => paginate(currentPage + 1)}
                                    >
                                        Siguiente
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            ) : (<div>No tienes permisos</div>)}
        </div>
    );
};

export default Employee;
