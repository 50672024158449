import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../../Components/Sidebar';
import api from '../../../api/api';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import "./Styles.css"
import { AuthContext } from '../../../Context/AuthContext';
import { mostrarMensajeAlerta } from '../../../util/alet';


export default function EditServiceData() {
    const { user } = useContext(AuthContext);
    const navigator = useNavigate();
    const { id } = useParams(); // Obtener el ID del servicio de los parámetros de la URL

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '',
        gender: '',
        type: '',
    });

    useEffect(() => {
        const fetchServiceData = async () => {
            try {
                const response = await api.get(`/service/${id}`);
                if (response.status === 200) {
                    const serviceData = response.data.service; // Supongamos que los datos vienen en un objeto con las mismas claves que en el formulario
                    setFormData({
                        ...formData,
                        name: serviceData.name,
                        description: serviceData.description,
                        duration: serviceData.duration,
                        gender: serviceData.gender,
                        type: serviceData.type,
                    });
                }
            } catch (error) {
                console.error('Error al cargar los datos del servicio:', error);
            }
        };

        fetchServiceData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user.permisos?.servicios.editar) {

            try {
                const response = await api.put(`/service/${id}`, formData); // Utilizar una solicitud PUT para actualizar el servicio
                if (response.status === 200) {

                    mostrarMensajeAlerta('success', "Éxito",
                        '¡Servicio actualizado exitosamente!', false);
                    setTimeout(() => {
                        navigator('/servicios');
                    }, 1500);
                }
            } catch (error) {
                console.error('Error al actualizar el servicio:', error);
                mostrarMensajeAlerta('error', "Error al actualizar el servicio",
                    'Ha ocurrido un error al actualizar el servicio.', true)
            }
        } else {
            mostrarMensajeAlerta("error", "Error",
                '¡No tienes Permisos!', true);
        }

    };

    return (
            <div className="container-fluid mt-5">
                <div className="row justify-content-center">
                    <div className="col-sm-8">
                        {user.permisos?.servicios.ver ? (
                            <div>
                                <h2>Editar Servicio</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Nombre del Servicio:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Descripción del Servicio:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Duración (en minutos):</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            value={formData.duration}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Género:</label>
                                        <select
                                            className="form-select"
                                            name="gender"
                                            value={formData.gender} // Convierte a minúsculas
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="Masculino">Masculino</option>
                                            <option value="Femenino">Femenino</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Tipo:</label>
                                        <select
                                            className="form-select"
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="Corporal">Corporal</option>
                                            <option value="Facial">Facial</option>
                                            <option value="Bronceado">Bronceado</option>
                                            <option value="Combo">Combo</option>
                                        </select>
                                    </div>
                                    <button type="submit" className="main-button">Actualizar Servicio</button> &nbsp; &nbsp;
                                    <Link to="/servicios"><button type="submit" className="main-close">Regresar</button></Link>
                                </form>
                            </div>
                        ) : (<div className='col-sm-5 mt-5'><p>No tienes Permisos</p></div>)}
                    </div>
                </div>
            </div>
    );
}
