import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';

const ReminderFormModal = ({ open, onClose, onSubmit, currentReminder }) => {
    const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm();
    const quillRef = useRef(null);
    const messageContent = watch('message', '');

    useEffect(() => {
        setValue('name', currentReminder?.name || '');
        setValue('frequency', currentReminder?.frequency || null);
        setValue('subject', currentReminder?.subject || '');
        setValue('description', currentReminder?.description || '');
        setValue('message', currentReminder?.message || '');
    }, [currentReminder, setValue]);

    const handleClose = () => {
        onClose();
    };



    const handleModalSubmit = async (data) => {
        onSubmit(currentReminder, data);
    };

    const insertTemplate = (template) => {
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        if (range) {
            editor.insertText(range.index, `${template}`);
        }
    };

    return (
        <Modal show={open} onHide={handleClose} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{currentReminder ? "Actualizar" : "Guardar"} Recordatorio</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(handleModalSubmit)}>
                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="name" className="mb-3">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingrese el nombre"
                                {...register('name', { required: "Por favor, ingrese un nombre." })}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name && errors.name.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="frequency" className="mb-3">
                            <Form.Label>Frecuencia</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Frecuencia"
                                {...register('frequency', {
                                    required: "Cantidad de días para envío después de la última cita.",
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "Solo se aceptan valores enteros.",
                                    },
                                    min: {
                                        value: 1,
                                        message: "El valor mínimo es 1.",
                                    },
                                })}
                                isInvalid={!!errors.frequency}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.frequency && errors.frequency.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="subject" className="mb-3">
                        <Form.Label>Asunto</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el asunto"
                            {...register('subject', { required: "Por favor, ingrese un asunto." })}
                            isInvalid={!!errors.subject}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.subject && errors.subject.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="description" className="mb-3">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la descripción"
                            {...register('description')}
                        />
                    </Form.Group>
                    <Form.Group controlId="message" className="mb-3">
                        <Form.Label>Mensaje</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={messageContent}
                            onChange={(value) => setValue('message', value)}
                            ref={quillRef}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, false] }], [{ 'font': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', { 'script': 'sub' }, { 'script': 'super' }],
                                    ['link', 'formula'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    ['clean']
                                ],
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.message && errors.message.message}
                        </Form.Control.Feedback>
                        <div className="mt-2">
                            <Button variant="outline-secondary" onClick={() => insertTemplate('{{name}}')} className="me-2">
                                Nombre
                            </Button>
                            <Button variant="outline-secondary" onClick={() => insertTemplate('{{lastName}}')} className="me-2">
                                Apellido
                            </Button>
                            <Button variant="outline-secondary" onClick={() => insertTemplate('{{number}}')} className="me-2">
                                Número de Telefono
                            </Button>
                            <Button variant="outline-secondary" onClick={() => insertTemplate('{{email}}')} className="me-2">
                                Email
                            </Button>
                            <Button variant="outline-secondary" onClick={() => insertTemplate('{{gender}}')} className="me-2">
                                Genero
                            </Button>
                            <Button variant="outline-secondary" onClick={() => insertTemplate('{{lastDate}}')} className="me-2">
                                Última cita
                            </Button>
                            <Button variant="outline-secondary" onClick={() => insertTemplate('⭐')} className="me-2">
                                ⭐
                            </Button>
                            <Button variant="outline-secondary" onClick={() => insertTemplate('🌈')} className="me-2">
                            🌈
                            </Button>
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" type="submit">
                        {currentReminder ? "Actualizar" : "Guardar"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ReminderFormModal;
